/* Site Typography */

/* CSS Frameworks */
/* Animate.css */
@use '../../../node_modules/animate.css/animate.min.css';

/* Fontawesome */
@use '../../../node_modules/fontawesome-free-6.2.1-web/css/all.min.css';

/* Bootstrap */
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

/* Fonts */
/* Headings*/
@font-face {
    font-family: JosefinSans;
    src: url(./Josefin_Sans/JosefinSans-VariableFont_wght.ttf);
}

/* Body */
@font-face {
    font-family: NunitoSans;
    src: url(./Nunito_Sans/NunitoSans-Regular.ttf)
}

/* Sass Variables */

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: ("100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: ("blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "black": $black,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800) !default;
// scss-docs-end colors-map

$bg-dark: rgb(33, 37, 41);
$logo-color: #009900;
$primary: #009900;
$primary-dark: #003300;
$primary-light: #00ff00;
$secondary: #ff6600;

$borderRadius: 8px;

/* For Fontawesome icons */
i {
    color: $logo-color;
}


/* Mixins for frequently used styles */
@mixin center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* font for body */
body {
    font-family: NunitoSans;
    font-size: 1rem;
    font-weight: 400;
    background-color: #f2f2f2;
}

/* font for headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: JosefinSans;
    font-weight: 600;
}

a {
    text-underline-offset: .2rem;
    transition: 200ms;

    :hover {
        color: $logo-color !important;
        font-weight: 700;
    }
}

.btn {
    height: 3rem;
    background-color: $white;
    border-color: $white;
    color: $logo-color;
    font-weight: 700;
    @include center-content();
    width: 10rem;
    margin: 1rem;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .7);
    transition: 1s;

    i {
        margin-left: 1rem;
        transition: 1s;
    }

    &:hover {
        background-color: $logo-color;
        border-color: $logo-color;
        color: $white;
        animation: pulse;
        animation-duration: 1s;
        animation-iteration-count: 1;

        i {
            color: $white;
        }
    }


}

.img-fluid {
    border-radius: .5rem;
    margin: 1rem;
    box-shadow: .5rem .5rem .5rem rgba(0, 0, 0, .5);
    transition: 2s;

    &:hover {
        margin: 0rem;
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: 1;
    }
}