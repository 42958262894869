@use 'typography'as *;

footer {
    font-size: 1rem;
    height: 4rem;
    padding: 2rem;
    background-color: $bg-dark;
    color: #fff;
    @include center-content;

    p {
        font-size: 1rem;
        margin: 0;
    }
}