@use 'typography'as *;


.calculator {

    padding-top: 4rem;

    .post-title {
        h3 {
            text-align: center;
            color: $logo-color;
        }

        h5 {
            text-align: center;
            font-weight: 400;
        }
    }

    .feature-image-holder {
        @include center-content;
        margin: 2rem 0;

        img {}
    }



    #mortgage-submit {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }


    .calculator-bottom {
        text-align: center;
    }
}

.post-text {

    pre {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 10%;
        background-color: lightgray;
        border-radius: 5px;
    }

    #mortgage-form {
        border-top: 2px solid black;
        margin-top: 1.5rem;
        margin-bottom: 2rem;

        label {
            margin-top: 1rem;
        }

        button {
            margin-top: 1rem;
            color: $primary;

            &:hover {
                box-shadow: 0px 0px 18px rgba($primary, 0.7);
                color: $white;
            }
        }
    }

    #mortgage-table {
        td {
            height: 2rem;
        }

        tbody tr {
            height: 3.7rem;

            th {
                position: relative;
                top: 20%;

            }

            td {
                position: relative;
                top: 20%;
                text-align: right;
                font-weight: 800;
                color: $primary;
            }
        }


    }

    #toggle-amort {
        background-color: $primary;
        color: $white;
        height: 2.25rem;
        display: flex;
        padding-left: 1rem;
        padding-right: 1rem;
        align-items: center;
        justify-content: center;
        border: 1px solid $primary;
        border-radius: 8px;

        &:hover {
            box-shadow: 0px 0px 18px rgba($primary, 0.7);
        }

    }

    #mortgage-amortization {
        margin-top: 3rem;

        h3 {
            text-align: center;
        }

        @include media-breakpoint-down(lg) {

            thead>tr>th:nth-child(2),
            tbody>tr>td:nth-child(2) {
                display: none;
            }


        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    #amortization-table {
        width: 90%;
        margin: 0 auto;

        tr th {
            text-align: right;
        }

        tr th:nth-child(1) {
            text-align: left;
        }

        tr td {
            text-align: right;
        }

    }

    #annuity-nav,
    #past-present-future-nav {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            color: gray;
            border: none;
            border-bottom: 1px solid gray;
            font-size: 2rem;
            margin-bottom: 2.5rem;
            font-family: 'Josefin Sans', sans-serif;
            background-color: rgba(0, 0, 0, 0);

            &.active {
                color: $primary;
                font-weight: 600;
            }
        }

    }


    #ppf-content {
        h3 {
            text-align: center;
        }

        #future,
        #past {
            display: none;
        }
    }


    #present-value-form {
        margin-bottom: 2rem;

        label {
            margin-top: 1rem;
            margin-bottom: .35rem;
            font-weight: 600;
            padding-left: .5rem;
        }

        button {
            margin-top: 2rem;
            color: $primary;
            padding: 1rem;
            border: none;
            font-weight: 700;
            box-shadow: 0 0 1.5rem rgba(0, 0, 0, .7);

            &:active,
            &:target,
            &:focus,
            &:focus-visible,
            &:hover {
                color: $white;
                outline: none;
                border: none;
                box-shadow: 0 0 2rem $secondary;
                background: linear-gradient(147deg,
                        $primary-dark,
                        $primary,
                        $primary-light,
                        $secondary 100%);
                color: #fff;

                i {
                    color: #fff;
                }
            }
        }
    }

    #pv-results {
        height: 100%;
        padding-top: 3rem;
        padding-bottom: 3rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .methodology {
            margin-top: 4rem;
        }
    }

    #income-annuity-form,
    #investment-annuity-form,
    #future-value-form {
        margin-bottom: 2rem;

        label {
            margin-top: 1rem;
            margin-bottom: .35rem;
            font-weight: 600;
            padding-left: .5rem;
        }

        button {
            margin-top: 2rem;
            color: $logo-color;
            padding: 1rem;
            border: none;
            font-weight: 800;
            box-shadow: 0 0 1.5rem rgba(0, 0, 0, .7);

            &:active,
            &:target,
            &:focus,
            &:focus-visible,
            &:hover {
                outline: none;
                border: none;
                box-shadow: 0 0 2rem rgba($secondary, .7);
                background: linear-gradient(147deg,
                        $primary-dark,
                        $primary,
                        $primary-light,
                        $secondary 100%);
                color: #fff;

                i {
                    color: #fff;
                }
            }
        }
    }

    #retirement-form,
    #past-value-form {
        margin-bottom: 2rem;

        label {
            margin-top: 1rem;
            margin-bottom: .35rem;
            font-weight: 600;
            padding-left: .5rem;
        }

        button {
            margin-top: 2rem;
            color: $primary;
            font-weight: 700;
            padding: 1rem;
            border: none;
            box-shadow: 0 0 1.5rem rgba(0, 0, 0, .7);

            &:active,
            &:target,
            &:focus,
            &:focus-visible,
            &:hover {
                color: $white;
                outline: none;
                border: none;
                box-shadow: 0 0 2rem $secondary;
                background: linear-gradient(147deg,
                        $primary-dark,
                        $primary,
                        $primary-light,
                        $secondary 100%);
                color: #fff;

                i {
                    color: #fff;
                }
            }
        }
    }

    #retirement-schedule {

        .for-small-screens {
            display: none;

            @include media-breakpoint-down(lg) {
                display: block;

                h5 {
                    margin: 2rem auto;
                    text-align: center;
                    color: $primary;
                }
            }
        }

        #amortization-table {
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    #annuity-intro {
        ul li {
            margin-bottom: 1rem;
        }
    }

    #annuity-content {
        h3 {
            text-align: center;
        }

        #investment {
            display: block;
        }

        #income {
            display: none;
        }


    }

    #retirement-planner {
        margin-top: 2.5rem;

        h3 {
            text-align: center;
        }
    }

    #retirement-addendum {
        color: #009900;
        font-weight: bold;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
            border-bottom: none;
        }

        i {}
    }

    #open-source {
        h2 {
            text-align: center;
            margin: 3rem auto;
            font-weight: 800;
        }
    }

}

.intro-text,
.stool-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    img {
        width: 80%;
        max-width: 400px;
        animation: zoomInDown;
        animation-duration: 1s;

        &:hover {
            animation: jello;
            animation-duration: 2s;
        }
    }
}