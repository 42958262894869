@use 'typography'as *;


.calculators {

    padding-top: 5rem;

    i {}
}

.calculator-intro {


    padding-bottom: 5rem;

    .titles .col-lg-5 {
        @include center-content;
    }

    .home-content .col-lg-7 {
        min-height: 60vh;
        @include center-content;
        flex-direction: column;

    }

    .home-button {
        @include center-content;

        a {
            font-size: 1.25rem;
        }
    }

    h1 {
        color: $logo-color;
        font-size: 4rem;

    }



    h2 {
        color: $logo-color;
        font-size: 2rem;
    }

    picture {
        @include center-content;
        margin-bottom: 3rem;

        img {

            transition: none;

            &:hover {
                animation: none;
            }
        }
    }


    li {
        font-size: 1.5rem;
        line-height: 2.5rem;
        padding-left: 2rem;
        margin-bottom: 3rem;


        i {
            font-size: 3rem;
        }

    }
}

.calculators-content {
    padding: 2rem 0;

    .calculator-excerpt {

        h3 {
            color: $logo-color;
        }

        padding: 2rem 0;
    }

}